'use client';

import { useSelector } from '@/core/lib/redux/hooks';
import { Layout } from '@/shared/components/layouts/Layout';

export default function Home() {
  const user = useSelector((state) => state.auth.user);

  return <Layout>{user ? <h1>Hi {user.name}</h1> : <h1>Hi</h1>}</Layout>;
}
